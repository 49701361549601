<template>
  <div class="my-project-requests-request">
    <div class="container-xl">
      <div class="card">
        <div class="card-header">
          <div class="row request-progress">
            <div v-for="(item, index) in menuItems" class="col-12 col-md-3">
              <span v-if="isMenuItemActive(item)" class="font-weight-bold">
                <span class="small mr-3 mr-md-0 d-md-block">Шаг {{ index + 1 }}</span>
                {{ item.name }}
              </span>
              <router-link v-else :to="{ name: item.route, params: { uuid }}" class="text-muted">
                <span class="small mr-3 mr-md-0 d-md-block">Шаг {{ index + 1 }}</span>
                {{ item.name }}
              </router-link>
            </div>
          </div>
          <div class="request-progress-bar">
            <div class="progress mt-3">
              <div class="progress-bar bg-success"
                   role="progressbar"
                   :style="{ width: progress + '%' }"
                   :aria-valuenow="Math.round(progress)"
                   aria-valuemin="0"
                   aria-valuemax="100"
              ></div>
            </div>
            <span class="ruble">&#8381;</span>
          </div>
        </div>
        <div class="card-body">
          <router-view @progress="updateItemProgress"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Request",
  data() {
    return {
      menuItems: [
        { name: "Общая информация", route: "myProjectRequests.request.general" },
        { name: "Учредители", route: "myProjectRequests.request.ownership" },
        { name: "Обеспечение", route: "myProjectRequests.request.collaterals" },
        { name: "Документы", route: "myProjectRequests.request.documents" }
      ],
      itemProgress: []
    }
  },
  computed: {
    uuid() {
      return this.$route.params.uuid;
    },
    activeIndex() {
      return this.menuItems.findIndex((item) => this.isMenuItemActive(item));
    },
    progress() {
      const index = this.activeIndex;
      const itemProgress = this.itemProgress[this.activeIndex] || 0
      return (index + itemProgress) / this.menuItems.length * 100;
    }
  },
  methods: {
    isMenuItemActive(item) {
      return this.$route.name === item.route;
    },
    updateItemProgress(progress) {
      this.itemProgress[this.activeIndex] = progress;
    }
  }
}
</script>

<style lang="scss">
.my-project-requests-request {
  .request-progress {
    font-size: 1.2rem;
  }

  .request-progress-bar {
    position: relative;

    .ruble {
      font-family: "Helvetica Neue", sans-serif;
      display: block;
      position: absolute;
      right: -0.325rem;
      top: -0.325rem;
      border: 0.125rem solid #1cbd73;
      border-radius: 0.75rem;
      background: #21e58a;
      color: #fff;
      font-size: 1rem;
      height: 1.5rem;
      width: 1.5rem;
      line-height: 1.5rem;
      text-align: center;
    }
  }
}
</style>
